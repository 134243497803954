"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from '@elements/dom-utils';

export function init() {
    onFind('.js-translate-following-mouse', function (baseElement) {
        let element = findIn('.js-translate-following-mouse__element', baseElement);

        if (!matchMedia('(min-width: 768px) and (pointer: fine)').matches || element === null || element.length === 0) {
            return;
        }

        let mouseX, mouseY;
        let bgFollowX = 0,
            bgFollowY = 0,
            moveX = 0,
            moveY = 0,
            friction = 1 / 60;

        executeFrame();

        function executeFrame() {
            requestAnimationFrame(executeFrame);
            moveBackground();
        }

        function moveBackground() {
            moveX += (bgFollowX - moveX) * friction;
            moveY += (bgFollowY - moveY) * friction;
            let translate = 'translate(' + moveX + 'px, ' + moveY + 'px) scale(1.1)';
            element.style.transform =  translate;
            console.log(translate);
        }

        baseElement.addEventListener("mousemove", function (evt) {
            mouseX = evt.clientX/window.innerWidth  * 100;
            mouseY = evt.clientY/window.innerHeight  * 100;

            let bgMouseX = Math.max(-100, Math.min(100, window.outerWidth / 2 - evt.clientX));
            let bgMouseY = Math.max(-100, Math.min(100, window.outerHeight / 2 - evt.clientY));

            bgFollowX = (20 * bgMouseX) / 100;
            bgFollowY = (10 * bgMouseY) / 100;
        });

    });
}
