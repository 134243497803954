"use strict";
import {on, find, findAllIn, findAll, hasClass} from '@elements/dom-utils';
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import axios from "axios";

export function init() {
    onFind('.js-dynamic-tabbing__toggle', function (element) {
        let tab = find(element.getAttribute('href'));

        if(hasClass('active', tab)){
            loadContent(tab, element.getAttribute('data-content-url'));
        }

        on('click', function () {
            loadContent(tab, element.getAttribute('data-content-url'));
        }, element);
    });
}

export function loadContent(tab, url, data, method) {
    let pendingRequest;

    pendingRequest = axios({
        method: method === 'GET' ? 'GET' : 'POST',
        url: url,
        headers: {'X-Requested-With': 'XMLHttpRequest'},
        data: data
    });

    asyncAppend({
        target: findAllIn('.js-dynamic-tabbing__content', tab),
        loading: findAll('.js-dynamic-tabbing__loading'),
        options: {
            allowScriptTags: true
        }
    }, pendingRequest.then((x) => x.data));

    initInScope(tab);

    return pendingRequest;
}
