import {add} from '@elements/scroll-animations';
import {findAll, find, findAllIn} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from '@elements/viewport-utils';

let selectors = {
    base: '.js-teaser-animation',
    teasers: '.js-teaser-animation__teaser'
};

export function init() {
    if (!matchMedia('(hover: none) and (pointer:coarse)').matches) {
        return;
    }

    onFind(selectors.base, function (baseElement) {
        let teasers = findAllIn(selectors.teasers, baseElement);

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const index = Array.from(teasers).indexOf(entry.target);
                    teasers.forEach(teaser => {
                        teaser.classList.remove("teaser-animation");
                    });
                    teasers[index].classList.add("teaser-animation");

                }
            })
        }, {
            threshold: 0,
            rootMargin: '-50% 0% -50% 0%',
        })


        teasers.forEach(teaser => {
            observer.observe(teaser);
        })
    });
}