import {add} from '@elements/scroll-animations';
import {findAll, find} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from '@elements/viewport-utils';

let settings = {
    offset: 100
};

export function init() {
    add(
        findAll('.js-portal-animation'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}
function calculateAnimationProgress(element) {
    const start = window.innerHeight* 2.2;
    const end = window.innerHeight;

    return {
        opacity: 1 - Math.min(
            Math.max(
                window.scrollY / (start - end),
                0
            ),
            1
        )
    };
}


// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    element.style.opacity = progress.opacity;
}
