"use strict";

import {add} from '@elements/scroll-animations';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-parallax', function (baseElement) {
        let parallaxElements = [];
        parallaxElements.push(baseElement);

        // animation for hero
        let continueParallax = baseElement.getAttribute('data-parallax-continue') || false;
        // if animation should only be on bottom of page
        let fadeInParallax = baseElement.getAttribute('data-parallax-fade-in') || false;

        // full screen
        let fullScreenParallax = baseElement.getAttribute('data-parallax-full-screen') || false;

        let noParallaxMobile = baseElement.getAttribute('data-parallax-no-mobile') || false;

        let parallaxXValue = baseElement.getAttribute('data-parallax-x-value') || 0;
        let parallaxYValue = baseElement.getAttribute('data-parallax-y-value') || 0;

        add(
            parallaxElements,
            calculateAnimationProgress({continueParallax, fadeInParallax, fullScreenParallax}),
            setAnimationProgress({parallaxXValue, parallaxYValue, noParallaxMobile})
        );
    });
}

const isMobile = () => matchMedia('(max-width: 767px)').matches;

// gets element -> returns number between 0 and 1
function calculateAnimationProgress({continueParallax, fadeInParallax, fullScreenParallax}) {
    return function(element) {
        let start = window.innerHeight - 100;
        let end = 300;
        let divider;
        let top = element.getBoundingClientRect().top;

        if(continueParallax) {
            end = 0;
            divider = end - start;
        } else if (fadeInParallax) {
            end = window.innerHeight / 1.4;
            divider = start - end;
        } else if (fullScreenParallax) {
            end =  0 - element.offsetHeight;
            divider = end - start;
        } else {
            divider = start - end;
        }

        let progress;


        progress =  Math.max(
            Math.min(
                (top - end) / (divider),
                1
            ),
            0
        );

        return progress;
    };
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress({parallaxXValue, parallaxYValue, noParallaxMobile}) {
    return function(element, progress) {
        if(noParallaxMobile !== false && isMobile()) return

        let parallaxMultiplicator = isMobile() ? 0.3 : 1;
        let parallaxXProgress,
            parallaxYProgress;

        parallaxXProgress = (progress * (parallaxXValue * parallaxMultiplicator)) + "px";
        parallaxYProgress = (progress * (parallaxYValue * parallaxMultiplicator)) + "px";

        element.style.transform = "translate3d(" + parallaxXProgress + ", "+ parallaxYProgress +", 0)";
    }
}