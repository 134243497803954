import { onFind } from '@elements/init-modules-in-scope'
import { on, find, findIn } from '@elements/dom-utils'
import { doInstantly } from "./microinteraction-helpers";

const selectors = {
    tabbingWrapper: '.js-tabbing-animations-wrapper',
    customIndicator: '.js-tabbing-animations-indicator',
    tabbingLink: '.js-tabbing-animations-link',
    activeTabbingLink: '.js-tabbing-animations-link.active'
}

export function init() {
    on("readystatechange", () => document.readyState == 'complete' && initAnimations(), document)

    function initAnimations() {
        onFind(selectors.customIndicator, () => {
            const tabbingWrapper = find(selectors.tabbingWrapper)
            const customIndicator = findIn(selectors.customIndicator, tabbingWrapper)

            setIndicatorToActiveLink()

            on("click", () => {
                setIndicatorToActiveLink()
            }, tabbingWrapper)

            on("resize", () => doInstantly(customIndicator, () => setIndicatorToActiveLink()), window)

            function setIndicatorToActiveLink() {
                const tabbingWrapperRect = tabbingWrapper?.getBoundingClientRect()
                const activeElementRect = findIn(selectors.activeTabbingLink, tabbingWrapper)?.getBoundingClientRect()

                const horizontallyScrolledInTabbing = tabbingWrapper.scrollLeft
                if(activeElementRect && tabbingWrapperRect && customIndicator) {
                    customIndicator.style.left = `${activeElementRect.left + horizontallyScrolledInTabbing - tabbingWrapperRect.left || 0}px`
                    customIndicator.style.right = `${tabbingWrapperRect.right - horizontallyScrolledInTabbing - (activeElementRect.right || 0) }px`
                }
            }
        })
    }
}
