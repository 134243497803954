
export function doInstantly(element, callback) {
    const oldTransitionValue = window.getComputedStyle(element).getPropertyValue('transition')
    element.style.transition = 'none';
    callback()
    doAsynchronously(() => {
        element.style.transition = oldTransitionValue
    })
}

export function doAsynchronously(callback) {
    setTimeout(() => {
        callback()
    }, 1)
}